<template>
  <v-container fluid>
    <v-row>
      <v-card class="mr-2">
        <div align="center">
          <h1>Service Agreement</h1>
        </div>
        Dear {{currentCase.participantDetails.firstname}},
        <br />
        <br />
        <h4>Who is making this agreement?</h4>
        This service agreement is between yourself, {{currentCase.participantDetails.firstname}} {{currentCase.participantDetails.lastname}} (or your trusted person) and {{currentOrganisation.name}}.
        <br />
        <br />
        <h4>Details</h4>
        <table width="1000">
          <tr>
            <td>
              <b>Participant:</b>
            </td>
            <td>
              <b>Service Provider:</b>
            </td>
          </tr>
          <tr>
            <td>{{currentCase.participantDetails.firstname}} {{currentCase.participantDetails.lastname}}</td>
            <td>{{currentOrganisation.name}}</td>
          </tr>
          <tr>
            <td>NDIS number: {{currentCase.participantDetails.nDISNumber}}</td>
            <td>ABN: {{currentOrganisation.abn}}</td>
          </tr>
          <tr>
            <td>
              <br />
              Start date of this Service Agreement: {{currentCase.planDetails.planStart}} End date of this Service Agreement: {{currentCase.planDetails.planEnd}}
            </td>
          </tr>
        </table>

        <h4>How does this agreement fit in with the NDIS?</h4>
        <div>This service agreement is made according to the rules and goals at the National Disability Insurance Scheme (NDIS).</div>

        <h4>What are my supports to be provided by {{currentOrganisation.name}}</h4>
        <div>This service agreement is made according to the rules and goals at the National Disability Insurance Scheme (NDIS).</div>
        {{currentCase.participantDetails.firstname}} has
        <b>chosen or not chosen</b>
        to {{currentOrganisation.name}} with provide their NDIS Support Plan
        <v-card-text>
          <v-form ref="form" @submit.prevent="validateBeforeSubmit">
            <v-col cols="12">
              <v-text-field
                v-model="vm.budget"
                label="Budget"
                required
                v-validate="'required'"
                data-vv-as="budget"
                name="budget"
                :error-messages="errors.collect('budget')"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="vm.details"
                label="Details"
                required
                v-validate="'required'"
                data-vv-as="details"
                name="details"
                :error-messages="errors.collect('details')"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="vm.supportPaid"
                label="How will the support be paid"
                required
                v-validate="'required'"
                data-vv-as="supportPaid"
                name="supportPaid"
                :error-messages="errors.collect('supportPaid')"
              ></v-text-field>
            </v-col>
            <h3>What is expected of you</h3>
            <h4>
              When you sign the Service Agreement, it means that you agree to do the things that are expected of you. These are called your responsibilities.
              <br />Your responsibilities include things like:
              <br />• Talking to
              <b>ORGANISATION</b> about what supports you would like and how you would like to receive them.
              • Being involved in the selection process of your appointed support staff.
              • Communicating with your support staff about your needs and how they can support you best.
              • Ensuring that when support staff commence, you treat them with respect.
              • Communicate with
              <b>ORGANISATION</b> about any concerns or issues.
              • Advising
              <b>ORGANISATION</b> if you can’t make an appointment. You should always give at least 24 hours notice to avoid having to pay for the cancelled shift.
              • Making sure that you maintain a safe work environment for the staff who work with you.
              • Informing
              <b>ORGANISATION</b> if you want to cease/end your service agreement or you want your NDIS plan reviewed.
            </h4>
            <br />
            <h3>Understanding your Service Agreement</h3>

            <h3>
              What you can expect of
              <b>ORGANISATION</b>
            </h3>

            <h4>
              <b>Organisation</b> responsibilities include:
              <br />• Ensure you get what you asked for
              • Being open and honest about the work they do
              • Communicate openly and clearly with you
              • Treat you with respect
              • Include you in all decision-making processes
              • Ensuring that your plan is being followed
              • Ensuring that staff are trained and have relevant experience
              • Storing and using your personal information correctly
              • Obeying all the rules and laws that apply.
              <br />This includes:
              <br />o Proving invoices or statements for your supports
              o Checking that the agreement is working well
              <br />We have agreed that the Agreement will be reviewed on (insert date). When we are reviewing the agreement, we will also update the
              <b>ORGANISATION</b> Risk Assessment profiles and any other supporting documentation as required.
            </h4>
            <br />
            <h3>How to pay for your supports</h3>
            <br />
            <h4>
              You currently receive funding from NDIS to attend
              <b>ORGANISATION</b> as outlined on page one of this Service Agreement. Any other supports or services outside of this (i.e. workwear, recreational expenses, taxi cost etc.) are to be purchased by yourself as
              <b>ORGANISATION</b> is unable to facilitate this service for you. For additional costs that you choose to purchase through
              <b>ORGANISATION</b> (i.e. attending community events using
              <b>ORGANISATIONM</b> vehicles and Employment)
              <b>ORGANISATION</b> will charge you at current rates. (see attached).
              Our Organisation reserves the right to increase costs in line with NDIA recommended charges.
              Some changes will also require review of your plan. This will involve NDIS and a planner to consider updates to your plan.
            </h4>
            <br />
            <h3>How to change your Service Agreement</h3>
            <h4>
              You can contact 1300 208 580 to discuss changes that you would like to make to the Agreement. In most cases,
              <b>ORGANISATION</b> will be able to change it. If they cannot make the changes you wish, then we will explain why. If changes are made to the Agreement, we will put these changes in writing and you will need to sign a document saying that you agree to the changes.
            </h4>
            <h3>How to end a Service Agreement</h3>
            <h4>
              If you want to end the Service Agreement you must tell us. You will need to let us know 6 weeks before you wish to end the agreement or as negotiated. This is called a notice period.
              <br />At all times, your exit from OMAR will be a planned, consultative process. We will discuss your exit with you and/or your advocate.
              <br />Serious breaches of this Agreement may result in immediate ending of this agreement without the 6 week notice period or as negotiated. Serious breaches may include but are not limited to:
              <br /> Abusing or assaulting staff or other customers
               Possessing, trafficking or supplying illicit drugs
               Using
              <b>ORGANISATION</b> premises for an illegal purpose
               Continually breaching the terms and conditions of this agreement
               Damaging or misusing
              <b>ORGANISATION</b> property
               Tacking action which is intended or would reasonably be expected to lead to unwanted or unfavourable publicity to
              <b>ORGANISATION</b>.
              <br />My Service Agreement will end straight away:
              <br /> If I run out of NDIS funding before the end date of this Agreement
               If I stop being a participant of the NDIS
               If my NDIS Plan is updated or I get a new plan
               I end the service before the end of my Service Agreement.
            </h4>
            <h4>What to do if you have a problem</h4>
            <v-col cols="12">
              <v-text-field
                v-model="vm.contactPerson"
                label="Contact the person"
                required
                v-validate="'required'"
                data-vv-as="contactPerson"
                name="contactPerson"
                :error-messages="errors.collect('contactPerson')"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="vm.contactPersonPosition"
                label="Position held"
                required
                v-validate="'required'"
                data-vv-as="contactPersonPosition"
                name="contactPersonPosition"
                :error-messages="errors.collect('contactPersonPosition')"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="vm.contactPersonNumber"
                label="Phone number"
                required
                v-validate="'required'"
                data-vv-as="contactPersonNumber"
                name="contactPersonNumber"
                :error-messages="errors.collect('contactPersonNumber')"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="vm.contactPersonEmail"
                label="Email"
                required
                v-validate="'required'"
                data-vv-as="contactPersonEmail"
                name="contactPersonEmail"
                :error-messages="errors.collect('contactPersonEmail')"
              ></v-text-field>
            </v-col>
            <h4>If you don't have any success getting your problem fixed, you can contact</h4>
            <v-col cols="12">
              <v-text-field
                v-model="vm.anotherContactName"
                label="Name"
                required
                v-validate="'required'"
                data-vv-as="anotherContactName"
                name="anotherContactName"
                :error-messages="errors.collect('anotherContactName')"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="vm.anotherContactPositionHeld"
                label="Position held"
                required
                v-validate="'required'"
                data-vv-as="anotherContactPositionHeld"
                name="anotherContactPositionHeld"
                :error-messages="errors.collect('anotherContactPositionHeld')"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="vm.anotherContactPhone"
                label="Phone Number"
                required
                v-validate="'required'"
                data-vv-as="anotherContactPhone"
                name="anotherContactPhone"
                :error-messages="errors.collect('anotherContactPhone')"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="vm.anotherContactEmail"
                label="Email"
                required
                v-validate="'required'"
                data-vv-as="anotherContactEmail"
                name="anotherContactEmail"
                :error-messages="errors.collect('anotherContactEmail')"
              ></v-text-field>
            </v-col>
            <h3>Goods and Service Tax (GST)</h3>
            <h4>
              The service you receive from
              <b>ORGANISATION</b> will not include GST. It is
              <b>ORGANISATION</b>’s responsibility to check whether GST will apply to the service you receive
              By signing the Agreement,
              <b>ORGANISATION</b> says that they have checked whether GST applies for any additional services or supports you choose to purchase from
              <b>ORGANISATION</b>. GST will be included in the cost.
            </h4>
            <h4>Participant contact details</h4>
            <v-col cols="12">
              <v-text-field
                v-model="vm.participantDaytimePhone"
                label="Your daytime phone number"
                required
                v-validate="'required'"
                data-vv-as="participantDaytimePhone"
                name="participantDaytimePhone"
                :error-messages="errors.collect('participantDaytimePhone')"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="vm.participantEveningPhone"
                label="Your evening phone number"
                required
                v-validate="'required'"
                data-vv-as="participantEveningPhone"
                name="participantEveningPhone"
                :error-messages="errors.collect('participantEveningPhone')"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="vm.mobileNumber"
                label="Your mobile number"
                required
                v-validate="'required'"
                data-vv-as="mobileNumber"
                name="mobileNumber"
                :error-messages="errors.collect('mobileNumber')"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="vm.email"
                label="Email"
                required
                v-validate="'required'"
                data-vv-as="email"
                name="email"
                :error-messages="errors.collect('email')"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="vm.participantaddress"
                label="Your home address"
                required
                v-validate="'required'"
                data-vv-as="participantaddress"
                name="participantaddress"
                :error-messages="errors.collect('participantaddress')"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="vm.participantAnotherContactName"
                label="The name of someone we can contact if we can't get in touch with you"
                required
                v-validate="'required'"
                data-vv-as="participantAnotherContactName"
                name="participantAnotherContactName"
                :error-messages="errors.collect('participantAnotherContactName')"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="vm.participantanotherContactPhone"
                label="Phone number"
                required
                v-validate="'required'"
                data-vv-as="participantanotherContactPhone"
                name="participantanotherContactPhone"
                :error-messages="errors.collect('participantanotherContactPhone')"
              ></v-text-field>
            </v-col>

            <h4>Organisation's contact details</h4>
            <v-col cols="12">
              <v-text-field
                v-model="vm.organisationPhoneNumber"
                label="Organisation phone number"
                required
                v-validate="'required'"
                data-vv-as="organisationPhoneNumber"
                name="organisationPhoneNumber"
                :error-messages="errors.collect('organisationPhoneNumber')"
                filled
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="vm.organisationMobileNumber"
                label="Organisation mobile number"
                required
                v-validate="'required'"
                data-vv-as="organisationMobileNumber"
                name="organisationMobileNumber"
                :error-messages="errors.collect('organisationMobileNumber')"
                filled
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="vm.organisationEmail"
                label="Email Address"
                required
                v-validate="'required'"
                data-vv-as="organisationEmail"
                name="organisationEmail"
                :error-messages="errors.collect('organisationEmail')"
                filled
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="vm.organisationAddress"
                label="Business Address"
                required
                v-validate="'required'"
                data-vv-as="organisationAddress"
                name="organisationAddress"
                :error-messages="errors.collect('organisationAddress')"
                filled
              ></v-text-field>
            </v-col>
            <h4>By signing this agreement, you agree to all information included</h4>
            <v-col cols="12">
              <v-text-field
                v-model="vm.signingParticipantName"
                label="Participant name"
                required
                v-validate="'required'"
                data-vv-as="signingParticipantName"
                name="signingParticipantName"
                :error-messages="errors.collect('signingParticipantName')"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="vm.signingParticipantSign"
                label="Signature"
                required
                v-validate="'required'"
                data-vv-as="signingParticipantSign"
                name="signingParticipantSign"
                :error-messages="errors.collect('signingParticipantSign')"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="vm.signingParticipantDate"
                label="Date"
                required
                v-validate="'required'"
                data-vv-as="signingParticipantDate"
                name="signingParticipantDate"
                :error-messages="errors.collect('signingParticipantDate')"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="vm.witnessDate"
                label="Witness Name"
                required
                v-validate="'required'"
                data-vv-as="witnessDate"
                name="witnessDate"
                :error-messages="errors.collect('witnessDate')"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="vm.witnessSignature"
                label="Witness signature"
                required
                v-validate="'required'"
                data-vv-as="witnessSignature"
                name="witnessSignature"
                :error-messages="errors.collect('witnessSignature')"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="vm.participantDate"
                label="Date"
                required
                v-validate="'required'"
                data-vv-as="participantDate"
                name="participantDate"
                :error-messages="errors.collect('participantDate')"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="vm.serviceProvider"
                label="Service Provider"
                required
                v-validate="'required'"
                data-vv-as="serviceProvider"
                name="serviceProvider"
                :error-messages="errors.collect('serviceProvider')"
                filled
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="vm.serviceRepresentative"
                label="Service Provider Representative"
                required
                v-validate="'required'"
                data-vv-as="serviceRepresentative"
                name="serviceRepresentative"
                :error-messages="errors.collect('serviceRepresentative')"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="vm.serviceSignature"
                label="Signature"
                required
                v-validate="'required'"
                data-vv-as="serviceSignature"
                name="serviceSignature"
                :error-messages="errors.collect('serviceSignature')"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="vm.serivceProviderDate"
                label="Date"
                required
                v-validate="'required'"
                data-vv-as="serivceProviderDate"
                name="serivceProviderDate"
                :error-messages="errors.collect('serivceProviderDate')"
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-radio-group v-model="vm.isPostalTheSame" label="Checklist for Service Agreement">
                <v-radio label="I know who is making the Agreement" v-bind:value="true"></v-radio>
                <v-radio label="I know what supports to include" v-bind:value="false"></v-radio>
                <v-radio label="I know what is expected of me" v-bind:value="true"></v-radio>
                <v-radio label="I know what is expected of my organisation" v-bind:value="false"></v-radio>
                <v-radio label="I know how the supports will be paid for" v-bind:value="true"></v-radio>
                <v-radio label="I know what to do if I want to make changes" v-bind:value="false"></v-radio>
                <v-radio
                  label="I know what to do if I want to end the agreement"
                  v-bind:value="true"
                ></v-radio>
                <v-radio
                  label="I know what to do if I have a problem and I know who to contact"
                  v-bind:value="false"
                ></v-radio>
                <v-radio
                  label="I have written my service agreement, or I have worked with my provider to write the agreement"
                  v-bind:value="true"
                ></v-radio>
                <v-radio
                  label="I have attached my Person-Centerd Plan to the agreement if I want to"
                  v-bind:value="false"
                ></v-radio>
                <v-radio label="I have a copy of the agreement of my records" v-bind:value="true"></v-radio>
                <v-radio label="I have attached a copy of my NDIS plan" v-bind:value="false"></v-radio>
              </v-radio-group>
            </v-col>

            <v-btn color="primary" type="submit">Continue</v-btn>
          </v-form>
        </v-card-text>
      </v-card>
    </v-row>
  </v-container>
</template>


<script>
import Vue from "vue";
import VeeValidate from "vee-validate";

Vue.use(VeeValidate);

export default {
  $_veeValidate: {
    validator: "new"
  },
  name: "service-agreement-form",
  data: () => ({
    e1: 0,
    date: new Date().toISOString().substr(0, 7),
    menu: false,
    vm: {},
    form: {},
    valid: true,

    supportArea: [
      "Support connenction",
      "Coordination of supports",
      "Assistance with accomodation and tenancy obligations ",
      "Life transition planning incliuding mentoring, peer support and individual skill development",
      "Assistance with decision making daily planning and budgeting"
    ],

    checkbox: false
  }),
  props: {
    msg: String
  },
  mounted() {
    this.init();
  },
  computed: {
    currentCase() {
      return this.$store.getters.currentCase;
    },
    currentOrganisation() {
      return this.$store.getters.currentOrganisation;
    }
  },
  methods: {
    init() {},
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    addParticipantDetails() {
      this.currentCase.participantDetails = this.vm;
      this.$store.dispatch("setCurrentCase", this.currentCase);
    },
    validateBeforeSubmit() {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.addParticipantDetails();
          this.$emit("next-step");
          return;
        } else {
          this.$store.dispatch("setToast", {
            message: "Form errors please correct before continuing",
            color: "red darken-4"
          });
        }
      });
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
h4 {
  color: green;
  font-weight: bold;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>

